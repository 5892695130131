import { Alert, AlertTitle, Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import AuthCard from '../../components/authentication/auth-card';
import LoginService from '../../functions/login-functions';

const ForgottenPassword = () => {
  // Hooks
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  // Validation Schema
  const schema = yup.object().shape({
    emailAddress: yup.string().required()
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitForm = async (formData) => {
    try {
      await LoginService.forgottenPassword(formData.emailAddress);
      navigate('/app');
    } catch (error) {
      setLoginError(error);
    }
  };

  return (
    <AuthCard
      title="Forgotten Password"
      subtitle="Enter your password and we will send you a password reset link"
    >
      {loginError && (
      <Alert
        variant="filled"
        severity="error"
      >
        <AlertTitle>Error</AlertTitle>
        {loginError.message}
      </Alert>
      )}
      <form
        id="forgotten-password-form"
        onSubmit={handleSubmit(submitForm, (error) => console.log(error))}
      >
        <TextField
          autoFocus
          fullWidth
          label="Email Address"
          variant="outlined"
          margin="normal"
          name="emailAddress"
          type="email"
          error={Boolean(errors?.email)}
          helperText={errors?.email?.message}
          {...register('emailAddress', { required: true })}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Reset Password
          </Button>
        </Box>
      </form>
    </AuthCard>
  );
};
export default ForgottenPassword;
