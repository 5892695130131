/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router';
import { SimpleNavbar } from './simple-navbar';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

export const SimpleLayout = ({ children }) => (
  <MainLayoutRoot>
    <SimpleNavbar />
    {children || <Outlet />}
  </MainLayoutRoot>
);
