import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Divider, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { ArticleCard } from '../../components/articles/article-card';
import { useMounted } from '../../hooks/use-mounted';
import ArticleService from '../../functions/article-functions';
import RestrictedComponent from '../../components/authentication/restricted-component';
import video from '../../static/videos/phone-scroll';
import CoverVideo from '../../components/cover-video';

const Article = () => {
  const isMounted = useMounted();
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  const getPosts = useCallback(async () => {
    try {
      const data = await ArticleService.getArticles();

      if (isMounted()) {
        setPosts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <CoverVideo video={video} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: useMediaQuery((theme) => theme.breakpoints.up('lg')) ? 8 : 2,
          mb: 8
        }}
      >
        <Container maxWidth="md">
          <RestrictedComponent requiredRole="Admin">
            <Card
              elevation={16}
              sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                my: 2,
                px: 3,
                py: 2
              }}
            >
              <Typography variant="subtitle1">
                Admin Only
              </Typography>
              <div>
                <Button
                  sx={{
                    display: {
                      sm: 'inline-flex'
                    },
                    mr: 2
                  }}
                  variant="contained"
                  onClick={() => navigate('articles/new')}
                >
                  New Article
                </Button>
              </div>
            </Card>
          </RestrictedComponent>
          <Card>
            <CardContent>
              <Box>
                <Typography
                  variant="h5"
                  sx={{ mb: 1 }}
                >
                  Articles
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Discover the latest Safeguarding news, tips and statistics from Learning Curve Group.
                </Typography>
              </Box>
            </CardContent>
          </Card>

          <Divider sx={{ my: 3 }} />
          {posts.map((post) => (
            <ArticleCard
              category={post.category}
              cover={post.cover}
              key={post.title}
              friendlyURL={post.friendlyURL}
              shortDescription={post.shortDescription}
              title={post.title}
            />
          ))}
        </Container>
      </Box>
    </>
  );
};

export default Article;
