import { Navigate } from 'react-router';
import Login from '../pages/authentication/login';
import Article from '../pages/articles/article-details';
import Articles from '../pages/articles/articles';
import ArticleCreate from '../pages/articles/new-article';
import ReportAConcern from '../pages/report-a-concern';
import Register from '../pages/authentication/register';
import ForgottenPassword from '../pages/authentication/forgotten-password';
import ProtectedRoute from './route-modifiers/protected-route';
import AuthenticationRoute from './route-modifiers/authentication-route';
import NotFound from '../pages/errors/404';
import { SimpleLayout } from './simple-layout';
import { MainLayout } from './main-layout';
import RestrictedRoute from './route-modifiers/restricted-route';
import Account from '../pages/account/account-details';
import EditAccount from '../pages/account/edit-account';
import ResetPassword from '../pages/authentication/reset-password';
import ChangePassword from '../pages/authentication/change-password';
import InviteAdmin from '../pages/invite-admin';

const routes = [
  {
    path: '/',
    element: <Navigate to="/app" />
  },
  {
    path: 'app',
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Articles />
      },
      {
        path: 'articles/:ArticleName',
        element: <Article />
      },
      {
        path: 'articles/:ArticleName/Edit',
        element: (
          <RestrictedRoute requiredRole="Admin">
            <ArticleCreate />
          </RestrictedRoute>)
      },
      {
        path: 'articles/new',
        element: (
          <RestrictedRoute requiredRole="Admin">
            <ArticleCreate />
          </RestrictedRoute>)
      },
      {
        path: 'report-concern',
        element: <ReportAConcern />
      },
      {
        path: 'account-details',
        element: <Account />
      },
      {
        path: 'account-details/edit',
        element: <EditAccount />
      },
      {
        path: 'account-details/change-password',
        element: <ChangePassword />
      },
      {
        path: 'invite-admin',
        element: (
          <RestrictedRoute requiredRole="Admin">
            <InviteAdmin />
          </RestrictedRoute>)
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: 'auth',
    element: (
      <AuthenticationRoute>
        <SimpleLayout />
      </AuthenticationRoute>),
    children: [
      {
        path: '',
        element: (
          <Login />
        )
      },
      {
        path: 'register',
        element: (
          <Register />
        )
      },
      {
        path: 'forgotten-password',
        element: (
          <ForgottenPassword />
        )
      },
      {
        path: 'reset-password',
        element: (
          <ResetPassword />
        )
      }
    ]
  },
  {
    path: '*',
    element: (
      <SimpleLayout>
        <NotFound />
      </SimpleLayout>)
  }
];

export default routes;
