import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  Snackbar,
  useMediaQuery,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Quill from 'react-quill';
import { FileDropzone } from '../../components/form-elements/file-dropzone';
import { fileToBase64 } from '../../utils/file-to-base64';
import ArticleService from '../../functions/article-functions';
import { useMounted } from '../../hooks/use-mounted';
import Loading from '../../components/loading';
// import QuillEditor from '../../components/form-elements/quill-editor';
import 'react-quill/dist/quill.snow.css';

const ArticleCreate = () => {
  // Hooks
  const [content, setContent] = useState();
  const [cover, setCover] = useState();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [article, setArticle] = useState(null);
  const { ArticleName } = useParams();
  const navigate = useNavigate();
  const isMounted = useMounted();

  // Validation Schema
  const schema = yup.object().shape({
    title: yup.string().required('An Article must have a title'),
    shortDescription: yup.string().required('An Article must have a description'),
    cover: yup.string().required('An Article must have a cover image'),
    content: yup.string().min(20).required('An Article must contain content')

  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  // Retrieve article to be edited from api
  const getPost = useCallback(async () => {
    try {
      const data = await ArticleService.getArticleDetails(ArticleName);
      if (isMounted()) {
        setArticle(data);
        setContent(data.content);
        setValue('content', data.content);
        setCover(data.cover);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  // On page load gets article from database if editing
  useEffect(() => {
    if (ArticleName) {
      getPost();
    } else {
      setLoading(false);
    }
  }, []);

  // Updates form value for image whenever it is changed
  useEffect(() => {
    setValue('cover', cover);
  }, [cover]);

  /**
   * Handles adding files to FileDropZone
   * @param {array} files
   */
  const handleDropCover = async ([file]) => {
    const data = await fileToBase64(file);
    setCover(data);
  };

  const handleRemove = () => {
    setCover(null);
  };

  const submitForm = async (data) => {
    setSubmitting(true);
    if (ArticleName) {
      const updateData = { ...data, databaseID: article.databaseID, friendlyURL: article.friendlyURL };
      try {
        await ArticleService.updateArticle(updateData);
        navigate(`/app/articles/${ArticleName}`);
      } catch (e) {
        setSubmitting(false);
        throw new Error('Failed to update article');
      }
    } else {
      try {
        await ArticleService.createArticle(data);
        navigate('/app');
      } catch (e) {
        setSubmitting(false);
        throw new Error('Failed to update article');
      }
    }
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: useMediaQuery((theme) => theme.breakpoints.up('lg')) ? 8 : 2,
          mb: 8
        }}
      >
        {loading ? (<Loading />) : (
          <Container maxWidth="md">

            <Card
              elevation={16}
              sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                mt: 4,
                px: 3,
                py: 2
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={4}
                  sm={8}
                >
                  <Typography
                    variant="h3"
                  >
                    {ArticleName ? 'Edit Article' : 'Create Article'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  textAlign="center"
                >
                  <Button
                    component="a"
                    sx={{
                      mr: 2
                    }}
                    variant="outlined"
                    onClick={() => navigate(ArticleName ? `/app/articles/${ArticleName}` : '/app')}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  textAlign="center"
                >
                  <LoadingButton
                    loading={submitting}
                    sx={{
                      mr: 2
                    }}
                    variant="contained"
                    type="submit"
                    form="article-form"
                  >
                    Publish
                  </LoadingButton>
                </Grid>
              </Grid>
            </Card>
            <form
              id="article-form"
              onSubmit={handleSubmit(submitForm, (erro) => console.log(erro))}
            >
              <Card sx={{ mt: 4 }}>
                <CardContent>
                  <Typography variant="h6">
                    Basic details
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <TextField
                      id="1"
                      type="text"
                      fullWidth
                      label="Post title"
                      defaultValue={article?.title}
                      error={Boolean(errors?.title)}
                      helperText={errors?.title?.message}
                      name="title"
                      {...register('title', { required: true })}
                    />
                    <Box sx={{ mt: 3 }}>
                      <TextField
                        fullWidth
                        label="Short description"
                        name="shortDescription"
                        {...register('shortDescription', { required: true })}
                        defaultValue={article?.shortDescription}
                        error={Boolean(errors?.shortDescription)}
                        helperText={errors?.shortDescription?.message}
                      />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ mt: 4 }}>
                <CardContent>
                  <Typography variant="h6">
                    Post cover
                  </Typography>
                  {cover ? (
                    <Box
                      sx={{
                        backgroundImage: `url(${cover})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        borderRadius: 1,
                        height: 230,
                        mt: 3
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        border: 1,
                        borderRadius: 1,
                        borderStyle: 'dashed',
                        borderColor: 'divider',
                        height: 230,
                        mt: 3,
                        p: 3
                      }}
                    >
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="h6"
                      >
                        Select a cover image
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 1 }}
                        variant="subtitle1"
                      >
                        Image used for the article cover
                      </Typography>
                    </Box>
                  )}
                  <Button
                    onClick={handleRemove}
                    sx={{ mt: 3 }}
                    disabled={!cover}
                  >
                    Remove photo
                  </Button>
                  <Box sx={{ py: 3 }}>
                    <FileDropzone
                      accept="image/*"
                      maxFiles={1}
                      onDrop={handleDropCover}
                    />
                  </Box>
                  {Boolean(errors?.cover) && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {errors?.cover?.message}
                    </Alert>
                  )}
                </CardContent>
              </Card>
              <Card sx={{ mt: 4 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ mb: 3 }}
                  >
                    Content
                  </Typography>
                  <Quill
                    placeholder="Write something"
                    sx={{
                      height: 330,
                      mt: 3
                    }}
                    defaultValue={content}
                    onChange={(data) => setValue('content', data)}
                  />
                  {Boolean(errors?.content) && (
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{ mt: 3 }}
                    >
                      {errors?.content?.message}
                    </Alert>
                  )}
                </CardContent>
              </Card>
            </form>
          </Container>
        )}
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          An error has occured, please try again later.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ArticleCreate;
