import { Box, Button } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import AuthCard from '../../components/authentication/auth-card';
import PasswordField from '../../components/form-elements/password-field';
import { useAuth } from '../../hooks/use-auth';

const ChangePassword = () => {
  // Hooks
  const navigate = useNavigate();
  const { login } = useAuth();

  // Validation Schema
  const schema = yup.object().shape({
    emailAddress: yup.string().required(),
    password: yup.string().required()
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitForm = async (formData) => {
    try {
      await login(formData);
      navigate('/app');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthCard
      title="Change Password"
      subtitle="Please enter your current and new passwords"
    >
      <form
        id="login-form"
        onSubmit={handleSubmit(submitForm, (error) => console.log(error))}
      >
        <PasswordField
          fullWidth
          label="Current Password"
          variant="outlined"
          margin="normal"
          name="currentPassword"
          error={Boolean(errors?.currentPassword)}
          helperText={errors?.currentPassword?.message}
          register={register('currentPassword', { required: true })}
        />
        <PasswordField
          fullWidth
          label="New Password"
          variant="outlined"
          margin="normal"
          name="newPassword"
          error={Boolean(errors?.newPassword)}
          helperText={errors?.newPassword?.message}
          register={register('newPassword', { required: true })}
        />
        <PasswordField
          fullWidth
          label="Confirm New Password"
          variant="outlined"
          margin="normal"
          name="confirmPassword"
          error={Boolean(errors?.confirmPassword)}
          helperText={errors?.confirmPassword?.message}
          register={register('confirmPassword', { required: true })}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Change Password
          </Button>
        </Box>
      </form>
    </AuthCard>
  );
};
export default ChangePassword;
