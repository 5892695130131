import { appConfig } from '../config';
import axios from './axios-config';

const ConcernReportingService = {
  getFormDetails: async () => {
    try {
      const response = await axios.get(`${appConfig.apiUrl}/forms/get-new-form`);
      return response.data.data;
    } catch (e) {
      throw new Error('Failed to retrieve form options');
    }
  },
  submitForm: async (data) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/forms`, data);
      return response;
    } catch (e) {
      throw new Error('Failed to submit concern');
    }
  }
};

export default ConcernReportingService;
