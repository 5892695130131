const removeToken = () => {
  localStorage.removeItem('authToken');
};

const setToken = (payload) => {
  localStorage.setItem('authToken', payload);
};

const getToken = () => localStorage.getItem('authToken');

export {
  removeToken,
  setToken,
  getToken
};
