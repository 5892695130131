/* eslint-disable react/prop-types */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PasswordField from './form-elements/password-field';
import { useAuth } from '../hooks/use-auth';
import AccountService from '../functions/account-functions';

const PasswordChangeDialog = ({ handleClose, open }) => {
  // Hooks
  const { user } = useAuth();

  // Regex
  const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  // Validation Schema
  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required('Please enter your current password.'),
    newPassword: yup
      .string()
      .required('Please enter a strong password.')
      .matches(
        passwordRegEx,
        'Must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character'
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords do not match')
      .required('Required')
  });

  const { register, handleSubmit, reset, clearErrors, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const onSubmit = async (data) => {
    const passwordObject = { emailAddress: user.emailAddress, oldPassword: data.oldPassword, newPassword: data.newPassword };
    try {
      clearErrors('oldPassword');
      await AccountService.changePassword(passwordObject);
      handleClose(); reset();
    } catch (error) {
      if (error.message === '401') {
        setError('oldPassword', { type: 'custom', message: error.message });
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change Password
        </DialogTitle>
        <DialogContent>
          <form
            id="change-password"
            onSubmit={handleSubmit(onSubmit, (erro) => console.log(erro))}
          >
            <PasswordField
              fullWidth
              label="Current Password"
              variant="outlined"
              margin="normal"
              name="oldPassword"
              error={Boolean(errors?.oldPassword)}
              helperText={errors?.oldPassword?.message}
              register={register('oldPassword', { required: true })}
            />
            <PasswordField
              fullWidth
              label="New Password"
              variant="outlined"
              margin="normal"
              name="newPassword"
              error={Boolean(errors?.newPassword)}
              helperText={errors?.newPassword?.message}
              register={register('newPassword', { required: true })}
            />
            <PasswordField
              fullWidth
              label="Confirm New Password"
              variant="outlined"
              margin="normal"
              name="confirmPassword"
              error={Boolean(errors?.confirmPassword)}
              helperText={errors?.confirmPassword?.message}
              register={register('confirmPassword', { required: true })}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            form="change-password"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PasswordChangeDialog;
