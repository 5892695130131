import PropTypes from 'prop-types';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export const ArticleCard = (props) => {
  const {
    cover,
    friendlyURL,
    shortDescription,
    title,
    ...other
  } = props;
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        '& + &': {
          mt: 6
        }
      }}
      {...other}
    >
      <CardActionArea onClick={() => navigate(`articles/${friendlyURL}`)}>

        <CardMedia
          component="img"
          src={cover}
          sx={{ height: '10vh' }}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
          >
            {title}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{
              height: 48,
              mt: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
            variant="body1"
          >
            {shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ArticleCard.propTypes = {
  cover: PropTypes.string.isRequired,
  friendlyURL: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
