import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Logo from './logo';

export const SimpleNavbar = () => (
  <AppBar
    elevation={0}
    sx={{
      backgroundColor: 'background.paper',
      borderBottomColor: 'divider',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      color: 'text.secondary'
    }}
  >
    <Container maxWidth="lg">
      <Toolbar
        disableGutters
        sx={{ minHeight: 64 }}
      >
        <RouterLink to="/">
          <Logo
            sx={{
              height: 40
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </Container>
  </AppBar>
);
