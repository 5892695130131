import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';

const AuthenticationRoute = ({ children }) => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return (
      <Navigate
        to="/app"
        replace
      />
    );
  }
  return children;
};

AuthenticationRoute.propTypes = {
  children: PropTypes.node
};

export default AuthenticationRoute;
