import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router';
import {
  useMediaQuery
} from '@mui/material';
import { MainNavbar } from './main-navbar';
import { MainSidebar } from './main-sidebar';
import LabelBottomNavigation from './bottom-nav';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

export const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <MainLayoutRoot>
      <MainNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <MainSidebar
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
        lgUp={lgUp}
      />
      <Outlet />
      {!lgUp && <LabelBottomNavigation />}
    </MainLayoutRoot>
  );
};
