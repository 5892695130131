import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useRoutes } from 'react-router-dom';
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context';
import { createTheme } from './theme/theme';
import routes from './layout/routes';
import { AuthProvider, AuthConsumer } from './contexts/jwt-context';
import Loading from './components/loading';

const App = () => {
  const content = useRoutes(routes);
  return (
    <AuthProvider>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeProvider
              theme={createTheme({
                responsiveFontSizes: true,
                mode: settings.theme
              })}
            >
              <CssBaseline />
              <Toaster position="top-center" />
              <AuthConsumer>
                {(auth) => (!auth.isInitialized
                  ? <Loading />
                  : (
                    content
                  ))}
              </AuthConsumer>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </AuthProvider>
  );
};

export default App;
