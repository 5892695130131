import jwtDecode from 'jwt-decode';
import { appConfig } from '../config';
import axios from './axios-config';

const AccountService = {

  identity: async (authToken) => {
    const decodedToken = jwtDecode(authToken);
    try {
      const response = await axios.get(`${appConfig.apiUrl}/account/${decodedToken.email}`);
      return { ...response.data.data, role: decodedToken.role };
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error?.response?.data?.statusDescription);
      } else {
        throw new Error('Failed to load user details. Please try again later.');
      }
    }
  },
  updateDetails: async (accountDetails) => {
    try {
      const response = await axios.put(`${appConfig.apiUrl}/account`, accountDetails);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error?.response?.data?.statusDescription);
      } else {
        throw new Error('Failed to load user details. Please try again later.');
      }
    }
  },
  changePassword: async (data) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/account/change-password`, data);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error('401');
      } else {
        throw new Error('Failed to change password, please try again later');
      }
    }
  }
};

export default AccountService;
