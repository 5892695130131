import PropTypes from 'prop-types';
import { AppBar, Box, Container, IconButton, Link, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import Logo from './logo';
import { AccountPopover } from './account-popover';

export const MainNavbar = (props) => {
  const { onOpenSidebar } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        color: 'text.secondary'
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{ minHeight: 64 }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40
              }}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            onClick={onOpenSidebar}
            sx={{
              display: {
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              alignItems: 'center',
              display: {
                lg: 'flex',
                xs: 'none'
              }
            }}
          >
            <Link
              color="textSecondary"
              sx={{ ml: 2 }}
              underline="none"
              variant="subtitle2"
              href="/app/report-concern"
            >
              Report a Concern
            </Link>
            <AccountPopover />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
