import { Alert, AlertTitle, Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import AuthCard from '../../components/authentication/auth-card';
import PasswordField from '../../components/form-elements/password-field';
import { useAuth } from '../../hooks/use-auth';

const Login = () => {
  // Hooks
  const [loginError, setLoginError] = useState(null);
  const { login } = useAuth();

  // Validation Schema
  const schema = yup.object().shape({
    emailAddress: yup.string().required('An email address is required'),
    password: yup.string().required('A password is required')
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const links = [{
    text: 'Forgotten Password',
    route: 'auth/forgotten-password'
  },
  {
    text: 'Register',
    route: 'auth/register'
  }];

  const submitForm = async (formData) => {
    try {
      await login(formData);
    } catch (error) {
      setLoginError(error);
    }
  };

  return (
    <AuthCard
      title="Log In"
      subtitle="Sign in to the Safeguarding App"
      links={links}
    >
      {loginError && (
        <Alert
          variant="filled"
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {loginError.message}
        </Alert>
      )}
      <form
        id="login-form"
        onSubmit={handleSubmit(submitForm, (error) => console.log(error))}
      >
        <TextField
          autoFocus
          fullWidth
          label="Email Address"
          variant="outlined"
          margin="normal"
          name="emailAddress"
          type="email"
          error={Boolean(errors?.emailAddress)}
          helperText={errors?.emailAddress?.message}
          {...register('emailAddress', { required: true })}
        />
        <PasswordField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          name="password"
          error={Boolean(errors?.password)}
          helperText={errors?.password?.message}
          register={register('password', { required: true })}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Log In
          </Button>
        </Box>
      </form>
    </AuthCard>
  );
};
export default Login;
