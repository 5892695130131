import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

const Error = ({ errorCode }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [error, setError] = useState();

  useEffect(() => {
    if (errorCode === 401) {
      setError({
        code: '401',
        message: 'Authorization required',
        description: `You either tried some shady route or you
              came here by mistake. Whichever it is, try using the
              navigation.`
      });
    } else if (errorCode === 404) {
      setError({
        code: '404',
        message: 'The page you are looking for isn’t here',
        description: `You either tried some shady route or you
              came here by mistake. Whichever it is, try using the
              navigation.`
      });
    } else {
      setError({
        code: '500',
        message: 'Internal Server Error',
        description: `You either tried some shady route or you
              came here by mistake. Whichever it is, try using the
              navigation.`
      });
    }
  }, []);

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexGrow: 1,
        py: '80px'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
        >
          {`${error?.code}: ${error?.message}`}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 0.5 }}
          variant="subtitle2"
        >
          {error?.body}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Box
            alt="Under development"
            component="img"
            src={`/static/error/error${errorCode}_${theme.palette.mode}.svg`}
            sx={{
              height: 'auto',
              maxWidth: '100%',
              width: 400
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Button
            component="a"
            variant="outlined"
            onClick={() => navigate('/app')}
          >
            Back to Home
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Error;

Error.propTypes = {
  errorCode: PropTypes.number.isRequired
};
