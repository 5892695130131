import { useState } from 'react';
import { Box, Card, CardContent, Container, Grid, ListItem, ListItemText, Typography, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '../../hooks/use-auth';
import PasswordChangeDialog from '../../components/change-password-dialog';
import CoverVideo from '../../components/cover-video';
import video from '../../static/videos/laptop';

const Account = () => {
  const { user } = useAuth();

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <CoverVideo video={video} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          alignItems: 'center',
          py: useMediaQuery((theme) => theme.breakpoints.up('lg')) ? 8 : 2,
          mb: 8
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          maxWidth="md"
        >
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Box>
                <Typography
                  variant="h5"
                  sx={{ mb: 1 }}
                >
                  Account Details
                </Typography>
                <ListItem>
                  <ListItemText
                    primary="Name"
                    secondary={`${user.firstName} ${user.lastName}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Email"
                    secondary={user.emailAddress}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Phone Number"
                    secondary={user.telephoneNumber}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Course"
                    secondary={user.courseName}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="City"
                    secondary={user.city}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Location"
                    secondary={user.location}
                  />
                </ListItem>
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Grid
                  item
                  xs={6}
                >
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    onClick={() => navigate('edit')}
                    size="small"
                  >
                    Edit Details
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    onClick={() => setChangePasswordOpen(true)}
                    size="small"
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <PasswordChangeDialog
        open={changePasswordOpen}
        handleClose={() => setChangePasswordOpen(false)}
      />
    </>
  );
};
export default Account;
