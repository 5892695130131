import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';
import AuthorizationRequired from '../../pages/errors/401';

const RestrictedRoute = ({ requiredRole, children }) => {
  const { user } = useAuth();

  return (
    requiredRole === user.role ? (children) : <AuthorizationRequired />
  );
};

RestrictedRoute.propTypes = {
  children: PropTypes.node,
  requiredRole: PropTypes.string
};

export default RestrictedRoute;
