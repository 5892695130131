import { Box, Button, Card, CardContent, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography, Select, MenuItem, InputLabel, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuid } from 'uuid';
import ConcernReportingService from '../functions/concern-form-functions';
import video from '../static/videos/sad';
import Loading from '../components/loading';
import CoverVideo from '../components/cover-video';
import SuccessDialog from '../components/success-dialog';

const ReportAConcern = () => {
  // Hooks

  // Field Hooks
  const [atRiskName, setAtRiskName] = useState('');
  const [reportersName, setReportersName] = useState('');
  const [contactDetails, setContactDetails] = useState('');
  const [concern, setConcern] = useState('');
  const [contactPriority, setContactPriority] = useState();
  const [contactType, setContactType] = useState({});
  const [concernType, setConcernType] = useState({});

  // Form Control Hooks
  const [reportingForSelf, setReportingForSelf] = useState();
  const [loading, setLoading] = useState(true);
  const [formDetails, setFormDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  // Validation Schema
  const schema = yup.object().shape({
    reportersName: yup.string().required('Please enter your full name'),
    contactDetails: yup.string().required('Please enter your contact details'),
    description: yup.string().required('Please enter a description of your concern'),
    atRiskName: yup.string().required('Please tell us whom you are raising this concern about')
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const getFormDetails = async () => {
    try {
      const data = await ConcernReportingService.getFormDetails();
      setFormDetails(data);

      setConcernType(data.concernTypes[0].databaseID);
      setValue('concernType', data.concernTypes[0]);
      setReportingForSelf(data.concernTypes[0].name === 'Myself');

      setContactType(data.contactTypes[0].databaseID);
      setValue('contactType', data.contactPriorities[0]);

      setContactPriority(data.contactPriorities[0].databaseID);
      setValue('contactPriority', data.contactPriorities[0]);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFormDetails();
  }, []);

  const handleVictimChange = (data) => {
    const tempConcernType = formDetails.concernTypes.find((o) => o.databaseID === Number(data.target.value));
    setValue('concernType', tempConcernType);
    setConcernType(tempConcernType.databaseID);
    if (tempConcernType?.name === 'Myself') {
      setReportingForSelf(true);
      setAtRiskName(reportersName || '');
      setValue('atRiskName', reportersName || '');
    } else {
      setReportingForSelf(false);
    }
  };

  const handleContactPreferenceChange = (data) => {
    const tempContactPreference = formDetails.contactTypes.find((o) => o.databaseID === Number(data.target.value));
    setValue('contactType', tempContactPreference);
    setContactType(tempContactPreference.databaseID);
  };

  const handleContactPriorityChange = (data) => {
    const tempContactPriority = formDetails.contactPriorities.find((o) => o.databaseID === Number(data.target.value));
    setValue('contactPriority', tempContactPriority);
    setContactPriority(data.target.value);
  };

  const handleSetReportersName = (data) => {
    setReportersName(data.target.value);
    if (reportingForSelf === true) {
      setAtRiskName(data.target.value);
      setValue('atRiskName', data.target.value);
    }
  };

  const onSubmit = async (data) => {
    const response = await ConcernReportingService.submitForm(data);
    if (response.status === 200) { setOpenDialog(true); }
  };

  const onError = (data) => {
    console.error(data);
  };

  return (
    <>
      <CoverVideo video={video} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          alignItems: 'center',
          py: useMediaQuery((theme) => theme.breakpoints.up('lg')) ? 8 : 2,
          pb: 4,
          mb: 8
        }}
      >
        {loading ? (<Loading />) : (
          <Container maxWidth="md">
            <Card>
              <CardContent>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ mb: 1 }}
                  >
                    Report a Concern
                  </Typography>
                  <form
                    onSubmit={handleSubmit(onSubmit, onError)}
                    id="concern-form"
                  >
                    <FormControl>
                      <FormLabel
                        id="concern-type-question-label"
                      >
                        Are you raising this concern about yourself or someone else?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="concern-type-buttons-label"
                        name="concern-type-buttons-group"
                        row
                        sx={{ width: '100%', justifyContent: 'center' }}
                        onChange={handleVictimChange}
                        value={concernType}
                      >
                        {formDetails.concernTypes.map((type) => (
                          <FormControlLabel
                            key={uuid()}
                            value={type.databaseID}
                            control={<Radio />}
                            label={type.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    {!reportingForSelf ? (
                      <TextField
                        id="at-risk-person-name"
                        label="At Risk Person's Name"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        margin="normal"
                        value={atRiskName}
                        {...register('atRiskName', { onChange: (data) => setAtRiskName(data.target.value), required: true })}
                        error={Boolean(errors?.atRiskName)}
                        helperText={errors?.atRiskName?.message}
                      />
                    ) : (
                      <input
                        type="hidden"
                        value={atRiskName}
                        {...register('atRiskName', { required: true })}
                      />
                    )}
                    <TextField
                      id="reporter-name"
                      label="Full Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={reportersName}
                      {...register('reportersName', { onChange: (data) => handleSetReportersName(data), required: true })}
                      error={Boolean(errors?.reportersName)}
                      helperText={errors?.reportersName?.message}
                    />
                    <FormControl>
                      <FormLabel
                        id="contact-type-question-label"
                      >
                        How do you wish to be contacted?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="contact-type-buttons-group-label"
                        name="contact-preference-buttons"
                        row
                        sx={{ width: '100%', justifyContent: 'center' }}
                        onChange={handleContactPreferenceChange}
                        value={contactType}
                      >
                        {formDetails?.contactTypes?.map((type) => (
                          <FormControlLabel
                            value={type.databaseID}
                            control={<Radio />}
                            label={type.name}
                            key={uuid()}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      id="contact-details"
                      label="Contact Details"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={contactDetails}
                      {...register('contactDetails', { onChange: (data) => setContactDetails(data.target.value), required: true })}
                      error={Boolean(errors?.contactDetails)}
                      helperText={errors?.contactDetails?.message}
                    />
                    <FormControl
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      <InputLabel id="contact-priority-select-label">Contact Priority</InputLabel>
                      <Select
                        labelId="contact-priority-select-label"
                        id="contact-priority-select"
                        label="Contact Priority"
                        onChange={handleContactPriorityChange}
                        value={contactPriority}
                      >
                        {formDetails?.contactPriorities?.map((priority) => (
                          <MenuItem
                            value={priority.databaseID}
                            key={uuid()}
                          >
                            {priority.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="concern-description"
                      label="Concern"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      multiline
                      value={concern}
                      {...register('description', { onChange: (data) => setConcern(data.target.value), required: true })}
                      error={Boolean(errors?.description)}
                      helperText={errors?.description?.message}
                    />
                  </form>
                </Box>
                <Button
                  form="concern-form"
                  type="submit"
                  variant="contained"
                  sx={{ width: '100%', mt: 1 }}
                >
                  Submit Concern
                </Button>
              </CardContent>
            </Card>
          </Container>
        )}
      </Box>
      <SuccessDialog
        open={openDialog}
        title="Report Sent!"
        description="Your concern has been passed along to the safeguarding team and a safeguarding officer will be in touch shortly"
        onClose={() => { setOpenDialog(false); navigate('/app'); }}
      />
    </>
  );
};
export default ReportAConcern;
