import { appConfig } from '../config';
import axios from './axios-config';

const LoginService = {
  login: async (loginCredentials) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/login`, loginCredentials);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error.response.data.statusDescription);
      } else {
        throw new Error('Authentication failed with unknown error. Please try again later.');
      }
    }
  },
  register: async (accountDetails) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/login/register`, accountDetails);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error?.response?.data?.statusDescription);
      } else {
        throw new Error('Registration failed with unknown error. Please try again later.');
      }
    }
  },
  registerAdmin: async (accountDetails) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/login/register-admin`, accountDetails);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error?.response?.data?.statusDescription);
      } else {
        throw new Error('Invitation failed with unknown error. Please try again later.');
      }
    }
  },
  forgottenPassword: async (emailAddress) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/login/forgot-password?emailAddress=${emailAddress}`);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error?.response?.data?.statusDescription);
      } else {
        throw new Error('Failed to send password reset, please try again later');
      }
    }
  },
  passwordReset: async (data) => {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/login/forgot-password-reset`, data);
      return response;
    } catch (error) {
      if (error.message.indexOf('401')) {
        throw new Error(error?.response?.data?.statusDescription);
      } else {
        throw new Error('Failed to send password reset, please try again later');
      }
    }
  }
};

export default LoginService;
