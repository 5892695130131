import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { SettingsButton } from '../components/settings-button';
import { useAuth } from '../hooks/use-auth';

export const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleRoute = (route) => {
    if (open) {
      setOpen();
    }
    navigate(route);
  };

  const handleLogout = async () => {
    if (open) {
      setOpen();
    }
    await logout();
    navigate('/auth');
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={() => setOpen(true)}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40
          }}
          src={user.avatar}
        >
          {`${user.firstName[0]}${user.lastName[0]}`}
        </Avatar>
      </Box>
      <Popover
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorRef.current}
        keepMounted
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{ sx: { width: 300 } }}
        transitionDuration={0}
      >
        <Box
          sx={{
            alignItems: 'center',
            p: 2,
            display: 'flex'
          }}
        >
          <Avatar
            src={user.avatar}
            sx={{
              height: 40,
              width: 40
            }}
          >
            {`${user.firstName[0]}${user.lastName[0]}`}
          </Avatar>
          <Box
            sx={{
              ml: 1
            }}
          >
            <Typography variant="body1">
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {user.role}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ my: 1 }}>

          <MenuItem onClick={() => { setOpen(false); handleRoute('/app/account-details'); }}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  Profile
                </Typography>
              )}
            />
          </MenuItem>

          <SettingsButton />
          <Divider />
          <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  Logout
                </Typography>
            )}
            />
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};
