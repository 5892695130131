import { Box, Card, Container, Divider, Link, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuid } from 'uuid';
import Logo from '../../layout/logo';

const AuthCard = ({ children, links, title, subtitle }) => (
  <Box
    component="main"
    sx={{
      backgroundColor: 'background.default',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}
  >
    <Container
      maxWidth="sm"
      sx={{
        py: {
          xs: '60px',
          md: '120px'
        }
      }}
    >
      <Card
        elevation={16}
        sx={{ p: 4 }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Logo
            sx={{
              height: 40,
              mb: '1.2rem'
            }}
          />
          <Typography
            variant="h5"
            sx={{ m: 2 }}
          >
            {title}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: 1
          }}
        >
          {children}
        </Box>
        {links?.length > 0 && (
          <>
            <Divider sx={{ my: 3 }} />
              {links?.map((link) => (
                <div key={uuid()}>
                  <Link
                    color="textSecondary"
                    variant="body2"
                    href={link.route}
                    underline="hover"
                  >
                    {link.text}
                  </Link>
                  <br />
                </div>
              ))}
          </>
        )}
      </Card>
    </Container>
  </Box>
);
AuthCard.defaultProps = {
  links: []
};
AuthCard.propTypes = {
  children: PropTypes.node.isRequired,
  links: PropTypes.array,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};
export default AuthCard;
