import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Logo from '../layout/logo';

const Loading = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%'
    }}
  >
    <Logo styleExtras={{ width: '20%', marginBottom: '2rem' }} />
    <CircularProgress />
  </Box>
);
export default Loading;
