import PropTypes from 'prop-types';
import logo from './logo-purple.svg';

const Logo = ({ sx }) => (
  <img
    style={{ ...sx }}
    alt="Learning Curve Group"
    src={logo}
  />
);

Logo.propTypes = {
  sx: PropTypes.object
};

Logo.defaultProps = {
  sx: {}
};

export default Logo;
