/* eslint-disable dot-notation */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import axios from '../functions/axios-config';
import LoginService from '../functions/login-functions';
import AccountService from '../functions/account-functions';
import { getToken, removeToken, setToken } from '../utils/jwt';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  UPDATEDETAILS: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const authToken = getToken();
        if (authToken) {
          axios.defaults.headers.common['authorization'] = `Bearer ${authToken}`;
          const user = await AccountService.identity(authToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async ({ emailAddress, password }) => {
    const response = await LoginService.login({ emailAddress, password });
    const authToken = response.data.data.token;
    setToken(authToken);
    axios.defaults.headers.common['authorization'] = `Bearer ${authToken}`;
    const user = await AccountService.identity(authToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    removeToken();
    dispatch({ type: 'LOGOUT' });
  };

  const updateDetails = async (updatedDetails) => {
    await AccountService.updateDetails(updatedDetails);
    const authToken = getToken();
    const decodedToken = jwtDecode(authToken);
    const user = { ...updatedDetails, role: decodedToken.role };

    dispatch({
      type: 'UPDATEDETAILS',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        updateDetails
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
