import { Alert, AlertTitle, Box, Button } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import AuthCard from '../../components/authentication/auth-card';
import PasswordField from '../../components/form-elements/password-field';
import LoginService from '../../functions/login-functions';

const ResetPassword = () => {
  // Hooks
  const [loginError, setLoginError] = useState(null);
  const [searchParams] = useSearchParams();

  // Regex
  const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  // Validation Schema
  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Please enter a strong password.')
      .matches(
        passwordRegEx,
        'Must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character'
      )
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const submitForm = async (formData) => {
    try {
      const response = await LoginService.passwordReset({
        newPassword: formData.password,
        resetToken: searchParams.get('token'),
        emailAddress: searchParams.get('email')
      });
      console.log(response);
    } catch (error) {
      setLoginError(error);
    }
  };

  return (
    <AuthCard
      title="Reset Password"
      subtitle="Enter your new password"
    >
      {loginError && (
        <Alert
          variant="filled"
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {loginError.message}
        </Alert>
      )}
      <form
        id="new-password-form"
        onSubmit={handleSubmit(submitForm, (error) => console.log(error))}
      >
        <PasswordField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          name="password"
          error={Boolean(errors?.password)}
          helperText={errors?.password?.message}
          register={register('password', { required: true })}
        />
        <PasswordStrengthBar password={watch('password')} />
        <Box sx={{ mt: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Reset Password
          </Button>
        </Box>
      </form>
    </AuthCard>
  );
};
export default ResetPassword;
