import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useMounted } from '../../hooks/use-mounted';
import ArticleService from '../../functions/article-functions';
import RestrictedComponent from '../../components/authentication/restricted-component';
import { PageLoader } from '../../components/page-loader';

const ArticleDetails = () => {
  // Hooks
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { ArticleName } = useParams();

  // Initial Page States
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPost = useCallback(async () => {
    await ArticleService.getArticleDetails(ArticleName).then((data) => {
      if (isMounted()) {
        setPost(data);
        setLoading(false);
      }
    }).catch((err) => {
      setError(err);
      setLoading(false);
    });
  }, [isMounted]);

  useEffect(() => {
    getPost();
  }, []);

  const deleteArticle = async () => {
    try {
      await ArticleService.deleteArticle(post.databaseID);
      navigate('/app');
    } catch (err) {
      console.log(err);
    }
  };
  if (loading || error) {
    return PageLoader({ error, loading });
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1
      }}
    >
      <Container maxWidth="md">
        <RestrictedComponent requiredRole="Admin">
          <Card
            elevation={16}
            sx={{
              alignItems: 'center',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              px: 3,
              py: 2,
              my: 2
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                sm={8}
              >
                <Typography variant="subtitle1">
                  Admin Only
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={2}
                textAlign="center"
              >
                <Button
                  component="a"
                  sx={{
                    mr: 2
                  }}
                  color="error"
                  variant="outlined"
                  onClick={() => deleteArticle()}
                >
                  Delete Article
                </Button>
              </Grid>
              <Grid
                item
                xs={4}
                sm={2}
                textAlign="center"
              >
                <Button
                  sx={{
                    mr: 2
                  }}
                  variant="contained"
                  onClick={() => navigate('edit')}
                >
                  Edit Article
                </Button>
              </Grid>
            </Grid>
          </Card>
        </RestrictedComponent>
        <Typography
          sx={{ mt: 3 }}
          variant="h3"
        >
          {post.title}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 3 }}
          variant="subtitle1"
        >
          {post.shortDescription}
        </Typography>
        <Box
          sx={{
            backgroundImage: `url(${post.cover})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: 1,
            height: 380,
            mt: 3
          }}
        />
        <Box sx={{ py: 3 }}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: post.content
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default ArticleDetails;
