import { appConfig } from '../config';
import axios from './axios-config';

const ArticleService = {
  createArticle: async (data) => {
    const newData = { ...data, CategoryDatabaseID: 1 };
    await axios.post(`${appConfig.apiUrl}/articles`, newData);
  },
  // Get all articles
  getArticles: async () => {
    try {
      const response = await axios.get(`${appConfig.apiUrl}/articles`);
      return response.data.data;
    } catch (e) {
      console.log(e);
      throw new Error('Failed to load articles');
    }
  },
  // Get specific article using friendly url
  getArticleDetails: async (articleName) => {
    const response = await axios.get(`${appConfig.apiUrl}/articles/${articleName}`).catch((error) => {
      throw error.toJSON().status;
    });
    return response.data.data;
  },
  updateArticle: async (data) => {
    const newData = { ...data, CategoryDatabaseID: 1 };
    await axios.put(`${appConfig.apiUrl}/articles`, newData);
  },
  deleteArticle: async (id) => {
    const response = await axios.delete(`${appConfig.apiUrl}/articles/${id}`).catch((error) => {
      throw error.toJSON().status;
    });
    return response.data.data;
  }
};

export default ArticleService;
