import { PropTypes } from 'prop-types';

const CoverVideo = ({ video }) => (
  <video
    src={`data:video/mp4;base64,${video}`}
    autoPlay
    loop
    muted
    width="100%"
    style={{ objectFit: 'cover', height: '11vh' }}
  />
);

CoverVideo.propTypes = {
  video: PropTypes.string
};

export default CoverVideo;
