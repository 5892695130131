import { Avatar, Button, Container, Dialog, Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PropTypes } from 'prop-types';

const SuccessDialog = ({ description, onClose, open, title }) => (

  <Dialog
    open={open}
  >
    <Container maxWidth="sm">
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
            color: 'success.main',
            mb: 2
          }}
        >
          <CheckCircleIcon fontSize="small" />
        </Avatar>
        <Typography variant="h5">
          {title}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          {description}
        </Typography>
        <Button
          fullWidth
          size="large"
          sx={{ mt: 4 }}
          variant="contained"
          onClick={onClose}
        >
          Continue
        </Button>
      </Box>
    </Container>
  </Dialog>
);

SuccessDialog.propTypes = {
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default SuccessDialog;
