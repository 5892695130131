import { useEffect, useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper } from '@mui/material';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '../hooks/use-auth';

const LabelBottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const defaultRoute = () => {
    if (location.pathname.includes('report-concern')) {
      return 'report-concern';
    }
    if (location.pathname.includes('account-details')) {
      return 'account';
    }
    if (location.pathname.endsWith('app')) {
      return 'articles';
    }
    if (location.pathname.includes('articles')) {
      return 'articles';
    }
    return null;
  };
  const [value, setValue] = useState(defaultRoute());

  const handleChange = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 'articles':
        navigate('/app');
        break;
      case 'report-concern':
        navigate('/app/report-concern');
        break;
      case 'account':
        navigate('/app/account-details');
        break;
      case 'invite-admin':
        navigate('/app/invite-admin');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setValue(defaultRoute());
  }, [location]);

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        sx={{ width: '100%' }}
        value={value}
        onChange={handleChange}
        showLabels
      >
        <BottomNavigationAction
          label="Articles"
          value="articles"
          icon={<NewspaperRoundedIcon />}
        />
        {user.role === 'Admin' ? (
          <BottomNavigationAction
            label="Invite Admin"
            value="invite-admin"
            icon={<ReportProblemRoundedIcon />}
          />
        ) : (
          <BottomNavigationAction
            label="Report Concern"
            value="report-concern"
            icon={<ReportProblemRoundedIcon />}
          />
        )}
        <BottomNavigationAction
          label="Account"
          value="account"
          icon={<ManageAccountsRoundedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default LabelBottomNavigation;
