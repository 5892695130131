import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Container, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '../../hooks/use-auth';
import CoverVideo from '../../components/cover-video';
import video from '../../static/videos/laptop';

const schema = Yup.object().shape({
  firstName: Yup
    .string()
    .required(),
  lastName: Yup
    .string()
    .required(),
  emailAddress: Yup
    .string()
    .required(),
  telephoneNumber: Yup
    .string()
    .required(),
  courseName: Yup
    .string(),
  city: Yup
    .string()
    .required(),
  location: Yup
    .string()
    .required()
});

const EditAccount = () => {
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {}
  });

  const { updateDetails, user } = useAuth();

  const navigate = useNavigate();

  const submitForm = async (data) => {
    await updateDetails(data);
    navigate('../account-details');
  };

  return (
    <>
      <CoverVideo video={video} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          alignItems: 'center',
          py: useMediaQuery((theme) => theme.breakpoints.up('lg')) ? 8 : 2,
          mb: 8
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          maxWidth="md"
        >
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Box>
                <Typography
                  variant="h5"
                  sx={{ mb: 2 }}
                >
                  Edit Details
                </Typography>
                <Box>
                  <form
                    id="account-details"
                    onSubmit={handleSubmit(submitForm, (erro) => console.log(erro))}
                  >
                    <TextField
                      label="First Name"
                      placeholder="Enter First Name"
                      defaultValue={user.firstName}
                      type="text"
                      errors={errors}
                      {...register('firstName', { required: true })}
                      fullWidth
                    />
                    <TextField
                      label="Last Name"
                      placeholder="Enter Last Name"
                      defaultValue={user.lastName}
                      type="text"
                      errors={errors}
                      {...register('lastName', { required: true })}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                    <TextField
                      disabled
                      label="Email"
                      placeholder="Enter Email"
                      defaultValue={user.emailAddress}
                      type="email"
                      errors={errors}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                    <input
                      type="hidden"
                      value={user.emailAddress}
                      {...register('emailAddress', { required: true })}
                    />
                    <TextField
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      defaultValue={user.telephoneNumber}
                      type="tel"
                      errors={errors}
                      {...register('telephoneNumber', { required: true })}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                    <TextField
                      label="Course"
                      placeholder="Enter Course"
                      defaultValue={user.courseName}
                      type="text"
                      errors={errors}
                      {...register('courseName', { required: true })}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                    <TextField
                      label="Location"
                      placeholder="Enter Location"
                      defaultValue={user.location}
                      type="text"
                      errors={errors}
                      {...register('location', { required: true })}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                    <TextField
                      label="City"
                      placeholder="Enter City"
                      defaultValue={user.city}
                      type="text"
                      errors={errors}
                      {...register('city', { required: true })}
                      sx={{ mt: 3 }}
                      fullWidth
                    />
                  </form>
                </Box>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{ width: '100%' }}
                      onClick={() => navigate('../account-details')}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Button
                      form="account-details"
                      color="primary"
                      type="submit"
                      variant="contained"
                      sx={{ width: '100%' }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>

              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
export default EditAccount;
