import { PropTypes } from 'prop-types';
import Error from '../pages/errors/error-page';
import Loading from './loading';

/**
 * Function to handle visuals while loading page data
 * @param {number} error Error code returned from API
 * @param {boolean} loading Loading state of page
 * @returns {JSX.Element} if loading or errored returns Error page or loading spinner respectively
 */
export const PageLoader = ({ error, loading }) => (
  <>
    {error && <Error errorCode={error} />}
    {loading && <Loading />}
  </>
);

PageLoader.propTypes = {
  error: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};
