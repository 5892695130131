import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import AuthCard from '../components/authentication/auth-card';
import SuccessDialog from '../components/success-dialog';
import LoginService from '../functions/login-functions';
import CoverVideo from '../components/cover-video';
import video from '../static/videos/laptop';

const InviteAdmin = () => {
  // Hooks
  const [openDialog, setOpenDialog] = useState(false);

  // Validation Schema
  const schema = yup.object().shape({
    emailAddress: yup.string()
      .email('Please make sure you have typed a valid email address.')
      .required('Please enter an Email address.'),
    firstName: yup.string().required('Please enter a first name.'),
    lastName: yup.string().required('Please enter a last name.')
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const onSubmit = async (formData) => {
    try {
      await LoginService.registerAdmin(formData);
      setOpenDialog(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CoverVideo video={video} />
      <AuthCard
        title="Invite Admin"
        subtitle="Send an invitiation to create a new admin account"
      >
        <form
          onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
        >
          <TextField
            fullWidth
            label="First Name"
            margin="normal"
            name="firstName"
            error={Boolean(errors?.firstName)}
            helperText={errors?.firstName?.message}
            {...register('firstName', { required: true })}
          />
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            name="lastName"
            error={Boolean(errors?.lastName)}
            helperText={errors?.lastName?.message}
            {...register('lastName', { required: true })}
          />
          <TextField
            autoFocus
            fullWidth
            label="Email Address"
            variant="outlined"
            margin="normal"
            name="emailAddress"
            type="email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            {...register('emailAddress', { required: true })}
          />
          <Box sx={{ mt: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Register
            </Button>
          </Box>
        </form>
      </AuthCard>
      <SuccessDialog
        open={openDialog}
        title="Invitation Successful"
        description="Your invitation has been sent! The new admin should recieve an email shortly."
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default InviteAdmin;
