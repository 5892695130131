import { useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { SettingsDrawer } from './settings-drawer';

export const SettingsButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={(
            <Typography variant="body1">
              Settings
            </Typography>
            )}
        />
      </MenuItem>
      <SettingsDrawer
        onClose={handleClose}
        open={open}
      />
    </>
  );
};
