import { useNavigate } from 'react-router';
import { Button } from '@mui/material';

export const HomeButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      component="a"
      variant="outlined"
      onClick={() => navigate('/app')}
    >
      Back to Home
    </Button>
  );
};
