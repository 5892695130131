import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import { SettingsButton } from '../components/settings-button';
import { useAuth } from '../hooks/use-auth';

const SidebarLink = ({ title, icon, onClick }) => (
  <MenuItem
    component="a"
    onClick={() => onClick()}
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={(
        <Typography variant="body1">
          {title}
        </Typography>
              )}
    />
  </MenuItem>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export const MainSidebar = (props) => {
  const { onClose, open, mdUp } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();

  const { user } = useAuth();

  const handleRoute = (route) => {
    if (open) {
      onClose();
    }
    navigate(route);
  };

  const handleLogout = async () => {
    if (open) {
      onClose();
    }
    await logout();
    navigate('/auth');
  };
  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={!mdUp && open}
      PaperProps={{ sx: { width: 256 } }}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 100
      }}
      variant="temporary"
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 40,
            width: 40
          }}
        >
          {`${user.firstName[0]}${user.lastName[0]}`}
        </Avatar>
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant="body1">
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {user.role}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <SidebarLink
          title="Profile"
          icon={(<AccountCircleIcon fontSize="small" />)}
          onClick={() => handleRoute('/app/account-details')}
        />
        <SettingsButton />
        <SidebarLink
          title="Logout"
          icon={(<LogoutIcon fontSize="small" />)}
          onClick={() => handleLogout()}
        />
      </Box>
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  mdUp: PropTypes.bool
};
