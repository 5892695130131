import { useAuth } from '../../hooks/use-auth';

const RestrictedComponent = ({ requiredRole, children }) => {
  const { user } = useAuth();

  return (
    requiredRole === user.role && (children)
  );
};
export default RestrictedComponent;
