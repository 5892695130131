import { useState } from 'react';
import { Box, Button, Checkbox, Link, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router';
import PasswordField from '../../components/form-elements/password-field';
import AuthCard from '../../components/authentication/auth-card';
import SuccessDialog from '../../components/success-dialog';
import LoginService from '../../functions/login-functions';

const Register = () => {
  // Hooks
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  // Regex
  const phoneRegEx = /^([+]\d{2})?\d{11}$/;
  const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  // Validation Schema
  const schema = yup.object().shape({
    firstName: yup.string().required('Please enter a first name.'),
    lastName: yup.string().required('Please enter a last name.'),
    telephoneNumber: yup.string().matches(phoneRegEx, 'Phone number is not valid').required('Please enter a valid phone number.'),
    emailAddress: yup.string()
      .email('Please make sure you have typed a valid email address.')
      .required('Please enter an Email address.'),
    password: yup
      .string()
      .required('Please enter a strong password.')
      .matches(
        passwordRegEx,
        'Must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character'
      ),
    // course: yup.string().required('Please enter your course name'),
    city: yup.string().required('Please enter your city.'),
    location: yup.string().required('Please enter location.')
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const links = [{
    text: 'Already have an account?',
    route: '/auth'
  }];

  const onSubmit = async (formData) => {
    try {
      await LoginService.register(formData);
      setOpenDialog(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AuthCard
        title="Register"
        subtitle="Register for the Safeguarding App"
        links={links}
      >
        <form
          onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
        >
          <TextField
            autoFocus
            fullWidth
            label="First Name"
            margin="normal"
            name="firstName"
            error={Boolean(errors?.firstName)}
            helperText={errors?.firstName?.message}
            {...register('firstName', { required: true })}
          />
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            name="lastName"
            error={Boolean(errors?.lastName)}
            helperText={errors?.lastName?.message}
            {...register('lastName', { required: true })}
          />
          <TextField
            fullWidth
            label="Phone Number"
            margin="normal"
            name="number"
            type="tel"
            pattern="[0-9]*"
            error={Boolean(errors?.telephoneNumber)}
            helperText={errors?.telephoneNumber?.message}
            {...register('telephoneNumber', { required: true })}
          />
          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            margin="normal"
            name="emailAddress"
            type="email"
            error={Boolean(errors?.emailAddress)}
            helperText={errors?.emailAddress?.message}
            {...register('emailAddress', { required: true })}
          />
          <PasswordField
            fullWidth
            label="Password"
            variant="outlined"
            margin="normal"
            name="password"
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
            register={register('password', { required: true })}
          />
          <PasswordStrengthBar password={watch('password')} />
          <TextField
            fullWidth
            label="Course Name"
            margin="normal"
            name="courseName"
            error={Boolean(errors?.course)}
            helperText={errors?.course?.message}
            {...register('courseName', { required: true })}
          />
          <TextField
            fullWidth
            label="City"
            margin="normal"
            name="city"
            error={Boolean(errors?.city)}
            helperText={errors?.city?.message}
            {...register('city', { required: true })}
          />
          <TextField
            fullWidth
            label="Location"
            margin="normal"
            name="location"
            error={Boolean(errors?.location)}
            helperText={errors?.location?.message}
            {...register('location', { required: true })}
          />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              ml: -1,
              mt: 2
            }}
          >
            <Checkbox
              name="policy"
              value={acceptedConditions}
              onChange={(value) => setAcceptedConditions(value.target.checked)}
            />
            <Typography
              color="textSecondary"
              variant="body2"
            >
              I have read the
              {' '}
              <Link
                component="a"
                href="https://www.learningcurvegroup.co.uk/terms-conditions"
                target="_blank"
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={!acceptedConditions}
            >
              Register
            </Button>
          </Box>
        </form>
      </AuthCard>
      <SuccessDialog
        open={openDialog}
        title="Registration Successful"
        description="Your account has been created successfully! We have sent you a confirmation email."
        onClose={() => navigate('/auth')}
      />
    </>
  );
};

export default Register;
